import React, { useEffect } from "react"

import i18n from "./i18n"

export const PageContext = React.createContext({})

export const PageContextProvider = ({ pageContext, children }) => {
  useEffect(() => {
    i18n.changeLanguage(pageContext.lang)
  }, [pageContext.lang])

  return (
    <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>
  )
}

export const usePageContext = () => React.useContext(PageContext)
