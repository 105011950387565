require("./src/styles/global.scss")
const React = require("react")
const { PageContextProvider } = require("./src/pageContext")

exports.wrapPageElement = ({ element, props }) => {
  return (
    <PageContextProvider pageContext={props.pageContext}>
      {element}
    </PageContextProvider>
  )
}
